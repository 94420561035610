import React, { useEffect, useState } from 'react'
import { Dialog } from '@reach/dialog'
import { PortableText } from "@portabletext/react";

const DefinitionModal = (props) => {
  const { textContentsQuery, definitions = [] } = props
  const [textContents, setTextContents] = useState([])
  const [definitionTerm, setDefinitionTerm] = useState({})
  const [showDialog, setShowDialog] = useState(false)

  useEffect(()=>{
    addDefinitionTermClickEvents()

    setTextContents([...document.querySelectorAll(textContentsQuery)])

    return () => {
      removeDefinitionTermClickEvents()
    }
  }, [])


  // handles on click events for glosary terms inside of text content blocks
  const onDefinitionClick = (event) => {
    const definitionTermLink = event.target.closest('.definition-term__link');
    if (!definitionTermLink) {
      return;
    }

    const currentDefinitionTerm = definitions.find(definition => definition.id == definitionTermLink.getAttribute('data-definition-id'));
    setDefinitionTerm(currentDefinitionTerm);
    setShowDialog(true);
  }

  const closeDialog = (event) => {
    setShowDialog(false)
  }

  const addDefinitionTermClickEvents = () => {
    const latestTextContents = [...document.querySelectorAll(textContentsQuery)]
    for(let textContent of latestTextContents) {
      textContent.addEventListener('click', onDefinitionClick)
    }
  }

  const removeDefinitionTermClickEvents = () => {
    for(let textContent of textContents) {
      textContent.removeEventListener('click', onDefinitionClick)
    }
  }

  return (
    <Dialog isOpen={showDialog} onDismiss={ () => { setShowDialog(false)}} aria-label='Definition Term' className='modal--white'>
      <div className='definition-modal__header'>
        <h1>
          { definitionTerm.title }
        </h1>
        <button className='definition-modal__close-button' aria-label='Close Definition term' onClick={closeDialog}>
        </button>
      </div>

      <div className='modal__text'>
        <PortableText value={definitionTerm.description} />
      </div>

      <div className='definition-modal__info'>
        {
          definitionTerm.relatedTerms
          ? <span className='definition-modal__detail'>
              <h5 className='definition-modal__heading'>
                Related terms
              </h5>
              <p className='modal__text'>
                { definitionTerm.relatedTerms || '-' }
              </p>
            </span>
          : null
        }
        {
          definitionTerm.source
          ? <span className='definition-modal__detail'>
              <h5 className='definition-modal__heading'>
                Source
              </h5>
              <p className='modal__text'>
                { definitionTerm.source || '-' }
              </p>
            </span>
          : null
        }
      </div>
    </Dialog>
  )
}

export default DefinitionModal
