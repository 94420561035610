import React from "react";
import { PortableText } from "@portabletext/react";
import ContentLink from "./ContentLink";
import DefinitionLink from "./DefinitionLink";
import TextContentTable from "../TextContentTable";

export const HEADING_URL_REGEX = /[^a-z0-9]+/g

const TextContentRenderer = (props) => {
  const { value, referenceIds } = props;
  const parameterize = (content) => {
    if(content[0] !== undefined) {
      return content[0].toLowerCase().replace(HEADING_URL_REGEX, "-");
    }
  };

  const components = {
    block: {
      h1: ({ children }) => (
        <h3 className="article-subheading" id={parameterize(children)}>
          {children}
        </h3>
      ),
      h2: ({ children }) => <h4 id={parameterize(children)}>{children}</h4>,
      h3: ({ children }) => <h5 id={parameterize(children)}>{children}</h5>,
      h4: ({ children }) => <h6 id={parameterize(children)}>{children}</h6>,
      normal: ({ children }) => (
        <>
          <p>{children}</p>
          <br />
        </>
      ),
    },
    types: {
      table: (object) => <TextContentTable rows={object.value.rows} />,
    },
    marks: {
      sub: ({ children }) => <sub>{children}</sub>,
      sup: ({ children }) => <sup>{children}</sup>,
      contentLink: ({ value, children }) => (
        <ContentLink contentId={value.content._ref} heading={value.heading}>
          {children}
        </ContentLink>
      ),
      definitionLink: ({ value, children }) => (
        <DefinitionLink definitionId={value.definition._ref}>
          {children}
        </DefinitionLink>
      ),
      formattedReferenceLink: ({ value, children }) => (
        <sup>{referenceIds.indexOf(value.formattedReference._ref) + 1}</sup>
      ),
    },
  };

  return <PortableText value={value} components={components} />;
};

export default TextContentRenderer;
