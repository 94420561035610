import React from "react";
const DefinitionLink = (props) => {
  const { definitionId, children } = props;
  return (
    <button className="definition-term__link" data-definition-id={definitionId}>
      {children}
    </button>
  );
};

export default DefinitionLink;
