import React from "react";

export const TrackerHead = () => {
  return (
    <div className='tracker-container'>
      <span className={`tracker-head tracker-hidden`}/>
      <span className={`tracker-body`}/>
      <span className={`tracker-tail`}/>
    </div>
  )
}

export const TrackerBody = () => {
  return (
    <div className='tracker-container'>
      <span className={`tracker-head`}/>
      <span className={`tracker-body`}/>
      <span className={`tracker-tail`}/>
    </div>
  )
}

export const TrackerTail = () => {
  return (
    <div className='tracker-container'>
      <span className={`tracker-head`}/>
      <span className={`tracker-body`}/>
      <span className={`tracker-tail tracker-hidden`}/>
    </div>
  )
}

export const LinelessTracker = () => {
  return (
    <div className='tracker-container'>
      <span className={`tracker-head tracker-hidden`}/>
      <span className={`tracker-body`}/>
      <span className={`tracker-tail tracker-hidden`}/>
    </div>
  )
}
