import React from 'react'
import GlossaryItem from './GlossaryItem'

const GlossaryList = (props) => {
  const { glossaries } = props

  return (
    <div className='glossary__list'>
      {
        glossaries.map((glossary) => (
          <GlossaryItem
            key={ glossary.title }
            {... glossary }
          />
        ))
      }
    </div>
  )
}

export default GlossaryList