import React from "react";
import { HEADING_URL_REGEX } from "./TextContentRenderer";

const ContentLink = (props) => {
  const { contentId, heading, children } = props;

  const formatHeading = (heading) => {
    if (heading !== undefined) {
      return heading.toLowerCase().replace(HEADING_URL_REGEX, "-");
    }
  };

  const formatUrl = (contentId, heading) => {
    return encodeURI(`/articles/${contentId}#${formatHeading(heading || "")}`);
  };

  return <a href={formatUrl(contentId, heading)}>{children}</a>;
};

export default ContentLink;
