import React, { useState } from 'react'

const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(false)

  const { name,
    id,
    value,
    content = props.children,
    onChange = () => {} 
  } = props


  const handleKeyPress = (event) => {
    if(event.key == " " || event.key == "Enter"){
      let checkbox = document.getElementById(event.target.htmlFor)
      checkbox.checked = !checkbox.checked
      setIsChecked(checkbox.checked)
      onChange(event)
    }
  }

  const handleOnChange = (event) => {
    setIsChecked(document.getElementById(event.target.id).checked)
    onChange(event)
  }

  return (
    <div className="checkbox">
      <input 
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={handleOnChange}
      />
      <label 
        htmlFor={id}
        role='checkbox'
        aria-checked={isChecked}
        onKeyPress={handleKeyPress}
        tabIndex={0}>
          {
            content
          }
      </label>
    </div>
  )
}

export default Checkbox