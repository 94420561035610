import React, { useState, useEffect } from "react"
import Checkbox from "./Checkbox"

const RequiredCheckboxGroup = (props) => {
  const { options, formId, inputFor, formName } = props
  const [checkboxValid, setCheckboxValid] = useState(false)
  const [displayWarning, setDisplayWarning] = useState('dn')
  const checkboxGroupRef = React.useRef()

  useEffect(() => {
    let form = document.getElementById(formId)
    form.addEventListener('submit', checkFormIsValid);

    return () => {
      form.removeEventListener('submit', checkFormIsValid)
    }
  })

  const checkFormIsValid = (event) => {
    if (!checkboxValid) {
      event.preventDefault()
      setDisplayWarning('db')
      document.querySelector('.checkbox-warning').focus()
    }
  }

  const setValid = () => {
    setCheckboxValid(true)
    setDisplayWarning('dn')
  }

  const handleOnChange = () => {
    let checkboxes = checkboxGroupRef.current.querySelectorAll('input[type=checkbox]:checked')
    checkboxes.length > 0 ? setValid() : setCheckboxValid(false)
  }

  return (
    <div ref={checkboxGroupRef}>
      <span tabIndex={0} className={`${displayWarning} paragraph__regular--bold--warning checkbox-warning`}>
        Please select at least one of the below before submitting feedback
      </span>
      { options.map((option) =>
        <Checkbox
          key={option.value}
          name={`${formName}[${inputFor}][]`}
          id={`${formName}_${inputFor}_${option.value}`}
          value={option.value}
          onChange={handleOnChange}
        >
          <p className='b'>{ option.name }</p>
          <p className='pl1'>{option.label}</p>
        </Checkbox>
      )}
    </div>
  )
}


export default RequiredCheckboxGroup