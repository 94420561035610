import React from 'react'
import { TrackerHead, TrackerBody, TrackerTail, LinelessTracker } from './AccordianTracker'
import cn from 'classnames'

const AccordianArticleItem = (props) => {
  const { article, isFirstArticle, isLastArticle, isCurrentArticle,  isBeforeCurrentArticle, isAfterCurrentArticle } = props

  const determineTracker = () => {
    if(isFirstArticle && isBeforeCurrentArticle) {
      return <LinelessTracker/>
    }

    if (isLastArticle && isAfterCurrentArticle) {
      return <LinelessTracker/>
    }

    if (isLastArticle && isCurrentArticle) {
      return <LinelessTracker/>
    }

    if (isCurrentArticle) {
      return <LinelessTracker/>
    }

    if (isBeforeCurrentArticle || isLastArticle) {
      return <TrackerTail/>
    }
    
    if (isFirstArticle || isAfterCurrentArticle || isCurrentArticle) {
      return <TrackerHead/>
    }

   return <TrackerBody/>
  }

  return (
    <div>
      <div className={`article-accordian__article-item ${cn({'current--article' : isCurrentArticle})}`}>
        { determineTracker() }
        <a 
          href={isCurrentArticle ? '' : article.url} 
          data-tracking-label={ 'Culture Sidenav Resource Link Followed' }>
          {article.title}
        </a>
      </div>
    </div>
  )
}

export default AccordianArticleItem