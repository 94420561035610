import React from 'react'
import useOpenable from '../hooks/UseOpenable'

const HeaderMenuButton = (props) => {
  const headerNavigation = document.querySelector('.header__navigation')

  const onOpen = () => {
    headerNavigation && headerNavigation.classList.add('header__navigation--open')
  }

  const onClose = () => {
    headerNavigation && headerNavigation.classList.remove('header__navigation--open')
  }

  const [isOpen, setIsOpen] = useOpenable(onOpen, onClose)

  return (
    <button className="header__menu-button" onClick={()=>{ setIsOpen(!isOpen) }}>
      <i className={`fas ${isOpen ? 'fa-times': 'fa-bars'}`}></i>
    </button>
  )
}

export default HeaderMenuButton