import React from 'react'
import { PortableText } from '@portabletext/react'

const GlossaryItem = (props) => {
  const { title, description, relatedTerms, source, imageUrl, anchor } = props

  return (
    <div className='glossary__item mt3 mb4'>
      <a className="glossary__anchor" id={ anchor }></a>
      <h2 className='glossary__title'>
        { title }
      </h2>

      {
        imageUrl && (
          <div className='right mb3' >
            <img src={ imageUrl } alt={ title }/>
          </div>
        )
      }

      <div className='glossary__description paragraph__width mb3'>
        <PortableText value={description}/>
      </div>

      <div className='glossary__info'>
        {
          relatedTerms
          ? <span className='glossary__detail'>
              <div className='glossary__header'>
                Related Terms
              </div>
              <div className='glossary__description'>
                { relatedTerms }
              </div>
            </span>
          : null
        }
        {
          source
          ? <span className='glossary__detail'>
              <div className='glossary__header'>
                Source
              </div>
              <div className='glossary__description'>
                { source }
              </div>
            </span>
          : null
        }
      </div>
    </div>
  )
}

export default GlossaryItem
