import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ScrollToHeadingDropDown from './ScrollToHeadingDropDown'

const ArticleBreadcrumbs = (props) => {
  return (
    <Breadcrumbs { ...props }>
      <ScrollToHeadingDropDown
        headingQuery='.article-subheading'
      />
    </Breadcrumbs>
  )
}

export default ArticleBreadcrumbs
