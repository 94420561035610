import React from 'react'
import GlossaryFilterButton from './GlossaryFilterButton'

const GlossaryFilterList = (props) => {
  const { defaultFilter, currentFilter, setFilter, filters } = props

  const filterButtons = [defaultFilter, ...filters].map((filter) => {
    return (
      <GlossaryFilterButton
        key={ filter }
        filter={ filter }
        selected={ filter == currentFilter }
        onClick={ () => { setFilter && setFilter(filter) } }
      />
    )
  })

  return (
    <div className='glossary__filters'>
      { filterButtons }
    </div>
  )
}

export default GlossaryFilterList