import React, { useState, useEffect } from 'react'

function useBannerVisible(initialVisibility) {
  const [bannerVisible, setBannerVisible] = useState(initialVisibility)

  const onBannerIntersectionObserved = (entries) => {
    setBannerVisible(entries.length && entries[0].isIntersecting )
  }

  const headerHeight = document.querySelector('.header__container').clientHeight

  const bannerIntersectionObserver = new IntersectionObserver(onBannerIntersectionObserved, { root: null, rootMargin: `-${headerHeight}px 0px 0px 0px`  })

  useEffect(() => {
    bannerIntersectionObserver.observe(document.querySelector('.header-banner'))
    return () => {
      bannerIntersectionObserver.unobserve(document.querySelector('.header-banner'))
    }
  }, [])

  return bannerVisible;
}

export default useBannerVisible