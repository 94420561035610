import React from 'react'
import BreadcrumbItem from './BreadcrumbItem'
import useBannerVisible from '../hooks/UseBannerVisible'
import cn from 'classnames'

const Breadcrumbs = (props) => {
  const { breadcrumbs, children } = props

  const bannerVisible = useBannerVisible(true)

  return (
    <div className='breadcrumbs__container'>
      <div className={ `breadcrumbs ${ cn({ 'breadcrumbs--fixed': !bannerVisible }) }` }>
        <ul className='breadcrumbs__list'>
          { breadcrumbs.map(breadcrumb => (<BreadcrumbItem key={ breadcrumb.title } { ...breadcrumb } />)) }
          <li className='breadcrumb-item'>
            {
              children
            }
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Breadcrumbs