import React from 'react'
import cn from 'classnames'

const GlossaryFilterButton = (props) => {
  const { selected, filter, onClick } = props
  return(
    <button className={`glossary__filter ${ cn({ 'glossary__filter--active': selected }) }`} onClick={onClick}>
      { filter }
    </button>
  )
}

export default GlossaryFilterButton