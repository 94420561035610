import React from 'react'
import AccordianArticleItem from './accordian/AccordianArticleItem'

const AccordianSideNav = (props) => {
  const { articles  } = props
  const articleTitleQuery = '.article-content__title'

  const currentArticleIndex = () => {
    return articles.findIndex(article => article.title == document.querySelector(articleTitleQuery).innerText)
  }

  const isCurrentArticle = (article) => {
    return articles.indexOf(article) == currentArticleIndex()
  }

  const isFirstArticle = (article) => {
    return articles.indexOf(article) == 0
  }

  const isLastArticle = (article) => {
    return articles.indexOf(article) == articles.length -1
  }

  const isBeforeCurrentArticle = (article) => {
    return articles.indexOf(article) == currentArticleIndex() - 1
  }

  const isAfterCurrentArticle = (article) => {
    return articles.indexOf(article) == currentArticleIndex() + 1
  }

  return (
    <div className='article-accordian__container'
    >
      { articles.map(article => (
        <AccordianArticleItem
          key={article.title}
          article={article}
          isFirstArticle={isFirstArticle(article)}
          isLastArticle={isLastArticle(article)}
          isCurrentArticle={isCurrentArticle(article)}
          isBeforeCurrentArticle={isBeforeCurrentArticle(article)}
          isAfterCurrentArticle={isAfterCurrentArticle(article)}/>
      ))}
    </div>
  )
}

export default AccordianSideNav