import React, { useState } from "react";
import { Dialog } from "@reach/dialog";
import { PortableText } from "@portabletext/react";

const OnboardingModal = (props) => {
  const { message } = props;
  const [showDialog, setShowDialog] = useState(true);

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <Dialog isOpen={showDialog} className="modal--white tr">
      <h1>A quick note</h1>
      <div className="modal__text">
        <PortableText value={message} />
      </div>
      <button className="contained-button" onClick={closeDialog}>
        I UNDERSTAND
      </button>
    </Dialog>
  );
};

export default OnboardingModal;
