import React from 'react'

const BreadcrumbItem = (props) => {
  const { title, url } = props
  return (
    <li className='breadcrumb-item'>
      <div className='breadcrumb-item__icon--right'></div>
      <a className='breadcrumb-item__text' href={ url } data-tracking-label={ 'Breadcrumb Link Followed' }>
        { title }
      </a>
    </li>
  )
}

export default BreadcrumbItem