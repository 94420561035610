import React, { useRef } from 'react'

const SearchBox = (props) => {
  const { searchPath } = props
  const textInputRef = useRef(null)

  const focusTextInput = (event) => {
    textInputRef && textInputRef.current.focus()
  }

  return (
    <div className="search__box" onClick={ () => { focusTextInput() }}>
      <form method='GET' action={searchPath}>
        <input ref={textInputRef} type='text' name='terms' placeholder='Search' required/>
        <button type='submit' aria-label='Search'>
          <i className='fas fa-search'></i>
        </button>
      </form>
    </div>
  )
}

export default SearchBox