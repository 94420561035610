import React from 'react'

const RequiredTextField = (props) => {
  const { id, labelText, type, value, placeholder, onChange } = props
  return (
    <div>
      <label htmlFor={id} className="required-input">
        {labelText}
      </label>
      <input value={value} onChange={onChange} className='form-input--text' placeholder={placeholder} id={id} type={type} required />
    </div>
  )
}

export default RequiredTextField