import React, { Fragment, useEffect, useState } from 'react'
import { Listbox, ListboxPopover, ListboxList, ListboxOption } from '@reach/listbox'
import useVisibleHeadings from '../../hooks/UseVisibleHeadings'

const ScrollToHeadingDropDown = (props) => {
  const { headingQuery } = props
  const headings = [...document.querySelectorAll(headingQuery)]
  const [selectedHeading, setSelectedHeading] = useState(headings[0] || null)
  const visibleHeadings = useVisibleHeadings([], headingQuery)


  const onHeadingSelected = (heading) => {
    headings.find(currentHeading => currentHeading.innerText == heading).scrollIntoView()
  }

  useEffect(()=>{
    const newSelectedHeading = (visibleHeadings[0] || selectedHeading)
    setSelectedHeading(newSelectedHeading ? newSelectedHeading : null)
  }, [visibleHeadings])

  return headings.length ? (
      <Fragment>
        <div className='breadcrumb-item__icon--right'></div>
        <Listbox onChange={ onHeadingSelected } className='dropdown' arrow={<div className='dropdown__arrow'></div>} value={ selectedHeading.innerText } >
          <ListboxPopover className='dropdown-list__popover'>
            <ListboxList className='dropdown-list'>
              {
                headings.map(heading => (
                  <ListboxOption key={ heading.innerText } value={ heading.innerText } className='dropdown-list__item'>
                    { heading.innerText }
                  </ListboxOption>
                ))
              }
            </ListboxList>
          </ListboxPopover>
        </Listbox>
      </Fragment>
    ) : null
}

export default ScrollToHeadingDropDown
