import React from 'react'

const GlossarySearch = (props) => {
  const { currentSearchTerm, onChange } = props

  return (
    <input
      className="glossary__search mv4"
      type='text'
      name='search'
      id='glossary-search'
      placeholder='Search'
      value={ currentSearchTerm }
      onChange={ (event) => { onChange(event.target.value)}}
    />
  )
}

export default GlossarySearch