import React, {useState, useEffect} from 'react'
import GlossaryFilterList from './GlossaryFilterList'
import GlossaryList from './GlossaryList'
import GlossarySearch from './GlossarySearch'

const Glossary = (props) => {
  const defaultFilter = props.defaultFilter || 'ALL'
  const defaultSearch = props.defaultSearch || ''
  const { glossaries } = props

  const [currentGlossaries, setCurrentGlossaries] = useState(glossaries)
  const [currentFilter, setCurrentFilter] = useState(defaultFilter)
  const [currentSearchTerm, setCurrentSearchTerm] = useState(defaultSearch)

  const availableGlossaryFilters = glossaries.map((glossary) => (glossary.title[0])).reduce((previous, current) => {
    return previous.includes(current)
      ? previous
      : [...previous, current]
  })

  const onFilterClicked = (filter) => {
    setCurrentFilter(filter)
    setCurrentSearchTerm(defaultSearch)
  }

  const onSearchChanged = (searchTerm) => {
    setCurrentFilter(defaultFilter)
    setCurrentSearchTerm(searchTerm)
  }

  useEffect(() => {
    setCurrentGlossaries(
      currentFilter == defaultFilter
        ? glossaries
        : glossaries.filter((currentGlossary) => {
          return currentGlossary.title.toUpperCase().match(new RegExp(`^${currentFilter}`))
        })
    )
  }, [currentFilter])

  useEffect(() => {
    setCurrentGlossaries(
      currentSearchTerm == defaultSearch
        ? glossaries
        : glossaries.filter((currentGlossary) => {
          return currentGlossary.title.toLowerCase().match(new RegExp(`${currentSearchTerm.toLowerCase()}`))
        })
    )
  }, [currentSearchTerm])

  return (
    <div className='glossary'>
      <GlossaryFilterList
        defaultFilter={ defaultFilter }
        filters={ availableGlossaryFilters }
        currentFilter={currentFilter}
        setFilter={onFilterClicked}
      />
      <GlossarySearch currentSearchTerm={ currentSearchTerm } onChange={ onSearchChanged }/>
      <hr className='horizontal_rule mb4 mt5 mt0-ns'/>
      <GlossaryList glossaries={ currentGlossaries }/>
    </div>
  )
}

export default Glossary