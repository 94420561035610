import { useState, useEffect } from "react";

const useOpenable = (onOpened, onClosed) => {
  const [isOpen, setIsOpen] = useState(null)

  useEffect(() => {
      isOpen ? onOpened() : onClosed()
  }, [isOpen])

  return [isOpen, setIsOpen]
}

export default useOpenable