import { useEffect, useState } from 'react'

function useVisibleHeadings(initialVisibleHeadings, headingQuery) {
  const [visibleHeadings, setVisibleHeadings] = useState(initialVisibleHeadings || [])

  const onHeadingObserved = (entries) => {
    for(let entry of entries) {
      const headingElement = entry.target

      if(entry.isIntersecting) {
        headingElement.setAttribute('data-heading-visible', true)
      } else {
        headingElement.setAttribute('data-heading-visible', false)
      }
    }

    setVisibleHeadings([...document.querySelectorAll('[data-heading-visible=true]')])
  }

  const headingObserver = new IntersectionObserver(onHeadingObserved, { root: null })

  useEffect(() => {
    document.querySelectorAll(headingQuery).forEach((heading) => {
      headingObserver.observe(heading)
    })

    return () => {
      document.querySelectorAll(headingQuery).forEach((heading) => {
        headingObserver.unobserve(heading)
      })
    }
  }, [])

  return visibleHeadings;
}

export default useVisibleHeadings
